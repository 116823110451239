/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import Wrapper from "../components/grid/Wrapper";
import Grid from "../components/grid/Grid";
import Row from "../components/grid/Row";
import Cell from "../components/grid/Cell";
import Layout from "../components/layout";
import { Headline, Text } from "../components/text";
import SEO from "../components/seo";
import { Link } from "gatsby";

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Wrapper>
            <Grid
                css={css`
                    margin-bottom: 10rem;
                `}
            >
                <Row
                    css={css`
                        margin-top: 22rem;
                        margin-bottom: 1rem;
                    `}
                >
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 12 / 12
                        }}
                    >
                        <Headline>Impressum</Headline>
                    </Cell>
                </Row>
                <Row>
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 3 / 12
                        }}
                    >
                        <Text>Angaben gemäß § 5 TMG</Text>
                    </Cell>
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 8 / 12
                        }}
                    >
                        <Text>
                            Matthias Dunker <br />
                            <span
                                css={css`
                                    user-select: none;
                                    &::before {
                                        content: "Schönf";
                                    }
                                    &:: after {
                                        content: " Straße 10";
                                    }
                                `}
                            >
                                ließer
                            </span>
                            <br />
                            <span
                                css={css`
                                    user-select: none;
                                    &::before {
                                        content: "10439 ";
                                    }
                                    &:: after {
                                        content: "lin";
                                    }
                                `}
                            >
                                Ber
                            </span>
                        </Text>
                    </Cell>
                </Row>
                <Row>
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 8 / 12
                        }}
                        offset={{
                            lg: 3 / 12
                        }}
                    >
                        <Text>Vertreten durch: Matthias Dunker</Text>
                        <p>
                            Kontakt: <br />
                            Telefon:{" "}
                            <span
                                css={css`
                                    user-select: none;
                                    &::before {
                                        content: "+49.17";
                                    }
                                    &:: after {
                                        content: "33392";
                                    }
                                `}
                            >
                                8.55
                            </span>
                            <br />
                            <br />
                            E-Mail:{" "}
                            <Link
                                to="/#contact"
                                css={css`
                                    user-select: none;
                                    &::before {
                                        content: "mail@";
                                    }
                                    &:: after {
                                        content: "dunker.de";
                                    }
                                `}
                            >
                                matthias
                            </Link>
                            <br />
                        </p>
                        <p>
                            Umsatzsteuer-ID: <br />
                            Umsatzsteuer-Identifikationsnummer gemäß §27a
                            Umsatzsteuergesetz:{` `}
                            <span
                                css={css`
                                    user-select: none;
                                    &::before {
                                        content: "DE29";
                                    }
                                    &:: after {
                                        content: "094";
                                    }
                                `}
                            >
                                6132094
                            </span>
                        </p>
                        <p>
                            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                            <br />
                            Matthias Dunker
                        </p>
                    </Cell>
                </Row>
                <Row>
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 3 / 12
                        }}
                    >
                        <Text>Haftungsausschluss:</Text>
                    </Cell>
                    <Cell
                        span={{
                            sm: 11 / 12,
                            lg: 8 / 12
                        }}
                    >
                        <p>
                            Haftung für Inhalte
                            <br />
                            Die Inhalte unserer Seiten wurden mit größter
                            Sorgfalt erstellt. Für die Richtigkeit,
                            Vollständigkeit und Aktualität der Inhalte können
                            wir jedoch keine Gewähr übernehmen. Als
                            Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                            eigene Inhalte auf diesen Seiten nach den
                            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
                            10 TMG sind wir als Diensteanbieter jedoch nicht
                            verpflichtet, übermittelte oder gespeicherte fremde
                            Informationen zu überwachen oder nach Umständen zu
                            forschen, die auf eine rechtswidrige Tätigkeit
                            hinweisen. Verpflichtungen zur Entfernung oder
                            Sperrung der Nutzung von Informationen nach den
                            allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                            diesbezügliche Haftung ist jedoch erst ab dem
                            Zeitpunkt der Kenntnis einer konkreten
                            Rechtsverletzung möglich. Bei Bekanntwerden von
                            entsprechenden Rechtsverletzungen werden wir diese
                            Inhalte umgehend entfernen.
                            <br />
                            <br />
                            Haftung für Links
                            <br />
                            Unser Angebot enthält Links zu externen Webseiten
                            Dritter, auf deren Inhalte wir keinen Einfluss
                            haben. Deshalb können wir für diese fremden Inhalte
                            auch keine Gewähr übernehmen. Für die Inhalte der
                            verlinkten Seiten ist stets der jeweilige Anbieter
                            oder Betreiber der Seiten verantwortlich. Die
                            verlinkten Seiten wurden zum Zeitpunkt der
                            Verlinkung auf mögliche Rechtsverstöße überprüft.
                            Rechtswidrige Inhalte waren zum Zeitpunkt der
                            Verlinkung nicht erkennbar. Eine permanente
                            inhaltliche Kontrolle der verlinkten Seiten ist
                            jedoch ohne konkrete Anhaltspunkte einer
                            Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
                            von Rechtsverletzungen werden wir derartige Links
                            umgehend entfernen.
                            <br />
                            <br />
                            Urheberrecht
                            <br />
                            Die durch die Seitenbetreiber erstellten Inhalte und
                            Werke auf diesen Seiten unterliegen dem deutschen
                            Urheberrecht. Die Vervielfältigung, Bearbeitung,
                            Verbreitung und jede Art der Verwertung außerhalb
                            der Grenzen des Urheberrechtes bedürfen der
                            schriftlichen Zustimmung des jeweiligen Autors bzw.
                            Erstellers. Downloads und Kopien dieser Seite sind
                            nur für den privaten, nicht kommerziellen Gebrauch
                            gestattet. Soweit die Inhalte auf dieser Seite nicht
                            vom Betreiber erstellt wurden, werden die
                            Urheberrechte Dritter beachtet. Insbesondere werden
                            Inhalte Dritter als solche gekennzeichnet. Sollten
                            Sie trotzdem auf eine Urheberrechtsverletzung
                            aufmerksam werden, bitten wir um einen
                            entsprechenden Hinweis. Bei Bekanntwerden von
                            Rechtsverletzungen werden wir derartige Inhalte
                            umgehend entfernen.
                            <br />
                            <br />
                            Datenschutz
                            <br />
                            Die Nutzung unserer Webseite ist in der Regel ohne
                            Angabe personenbezogener Daten möglich. Soweit auf
                            unseren Seiten personenbezogene Daten
                            (beispielsweise Name, Anschrift oder eMail-Adressen)
                            erhoben werden, erfolgt dies, soweit möglich, stets
                            auf freiwilliger Basis. Diese Daten werden ohne Ihre
                            ausdrückliche Zustimmung nicht an Dritte
                            weitergegeben. <br />
                            Der Website-Provider erhebt und speichert
                            automatisch in „Server Log Files“ Informationen, die
                            Ihr Browser automatisch an uns übermittelt. Dies
                            sind: Anonymisierte Client-IP, Timestamp,
                            Request-Zeile, Status Code, User Agent, der vom
                            Client gesendet wurde, Remote User.
                            <br />
                            Wir weisen darauf hin, dass die Datenübertragung im
                            Internet (z.B. bei der Kommunikation per E-Mail)
                            Sicherheitslücken aufweisen kann. Ein lückenloser
                            Schutz der Daten vor dem Zugriff durch Dritte ist
                            nicht möglich. <br />
                            Der Nutzung von im Rahmen der Impressumspflicht
                            veröffentlichten Kontaktdaten durch Dritte zur
                            Übersendung von nicht ausdrücklich angeforderter
                            Werbung und Informationsmaterialien wird hiermit
                            ausdrücklich widersprochen. Die Betreiber der Seiten
                            behalten sich ausdrücklich rechtliche Schritte im
                            Falle der unverlangten Zusendung von
                            Werbeinformationen, etwa durch Spam-Mails, vor.
                            {/* <br />
                                <br />
                                <br />
                                Google Analytics
                                <br />
                                <br />
                                Diese Website benutzt Google Analytics, einen
                                Webanalysedienst der Google Inc. (''Google'').
                                Google Analytics verwendet sog. ''Cookies'',
                                Textdateien, die auf Ihrem Computer gespeichert
                                werden und die eine Analyse der Benutzung der
                                Website durch Sie ermöglicht. Die durch den
                                Cookie erzeugten Informationen über Ihre
                                Benutzung dieser Website (einschließlich Ihrer
                                IP-Adresse) wird an einen Server von Google in
                                den USA übertragen und dort gespeichert. Google
                                wird diese Informationen benutzen, um Ihre
                                Nutzung der Website auszuwerten, um Reports über
                                die Websiteaktivitäten für die Websitebetreiber
                                zusammenzustellen und um weitere mit der
                                Websitenutzung und der Internetnutzung
                                verbundene Dienstleistungen zu erbringen. Auch
                                wird Google diese Informationen gegebenenfalls
                                an Dritte übertragen, sofern dies gesetzlich
                                vorgeschrieben oder soweit Dritte diese Daten im
                                Auftrag von Google verarbeiten. Google wird in
                                keinem Fall Ihre IP-Adresse mit anderen Daten
                                der Google in Verbindung bringen. Sie können die
                                Installation der Cookies durch eine
                                entsprechende Einstellung Ihrer Browser Software
                                verhindern; wir weisen Sie jedoch darauf hin,
                                dass Sie in diesem Fall gegebenenfalls nicht
                                sämtliche Funktionen dieser Website voll
                                umfänglich nutzen können. Durch die Nutzung
                                dieser Website erklären Sie sich mit der
                                Bearbeitung der über Sie erhobenen Daten durch
                                Google in der zuvor beschriebenen Art und Weise
                                und zu dem zuvor benannten Zweck einverstanden. */}
                        </p>
                    </Cell>
                </Row>
            </Grid>
        </Wrapper>
    </Layout>
);

export default IndexPage;
